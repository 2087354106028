/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
body, html {
  font-family: 'Lato', sans-serif !important;
}

.text-center {
  text-align: center;
}

.mouse-pointer {
  cursor: pointer;
}


// Google Translate
#google_translate_element {
  flex: auto !important;
  flex-direction: column;
}

.goog-te-combo {
  display: block;
  width: 100%;
  padding: 0.575rem 2.25rem 0.575rem 0.85rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #F8F8F8 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

body#lang-body .goog-te-gadget {
  font-family: arial !important;
  font-size: 11px !important;
  color: #666 !important;
  padding: 50px 0px !important;
  display: flex !important;
  justify-content: left !important;
  align-items: left !important;
  white-space: nowrap !important;
  flex-direction: column !important;
  margin-top: 100px !important;
  // padding-right: 25px !important;
  // padding-left: 25px !important;
  padding-top: 20px !important;
}


.goog-te-gadget {

  padding: 0px 0px;
  display: flex;
  justify-content: left;
  align-items: left;
  white-space: nowrap;
  height: 40px;
  flex-direction: column;
  margin-top: 0px;
  // padding-right: 15px;
  // padding-left: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.goog-te-gadget {
  color: transparent !important;
}

.goog-logo-link {
  display: none !important;
}


// Language inner hide
.goog-te-banner-frame, #goog-gt-tt, .skiptranslate {
  display: none !important;
}

.crosshairs .text-icon {
  font-size: 15px !important;
  color: #0000008a !important;
}

.lang-enable .skiptranslate {
  display: block !important;
}

input[type=radio] {
  width: 20px !important;
  margin-top: 3px;
  height: 20px !important;
}


input[type=checkbox] {
  width: 19px !important;
  height: 18px !important;
  margin-top: 3px;
}
//
//input[type=radio] + p, input[type=checkbox]{
//  padding-top: 4px !important;
//}

.VIpgJd-ZVi9od-l4eHX-hSRGPd {
  display: none !important;
}


.bank-ifsc-details{
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
    border-color:#AD0C62; 
  }
  
  .mat-radio-button.mat-accent .mat-radio-inner-circle{
    color:#AD0C62;
    background-color:#AD0C62 ;
  }
  
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
      background-color:#AD0C62;
  }
  
  
  .mat-form-field-wrapper{
    padding-bottom: 12px;
    margin-bottom: 5px !important;
  }
  
  .mat-form-field-infix{
    border-top: 4px solid transparent;
  }
  
  
  .mat-form-field-appearance-outline .mat-form-field-outline-thick{
    color: black;
  }
  
  
    // input outline color
    .mat-form-field-appearance-outline .mat-form-field-outline {
       color: #393C35 !important;
    }
  
    // mat-input focused color
    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
       color: #AD0C62 !important;
    }
  
  
    .mat-input-element {
      caret-color: #393C35 ;
  }
}

.login-page{
  .mat-form-field.mat-focused .mat-form-field-ripple{
    background-color: #0A4797;
  }

  .mat-input-element{
    caret-color: #0A4797;
  }

  .mat-form-field.mat-focused .mat-form-field-label, .mat-form-field.mat-focused.mat-primary .mat-select-arrow{
    color: #0A4797;
  }
}
